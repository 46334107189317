<template>
  <div>
    <div class="row">
      <div
        v-show="documentA && documentA != '{}'"
        class="col-6"
        @mouseover="enableScrollLeftBox"
        @mouseleave="disableScrollLeftBox"
      >
        <codemirror
          ref="leftBox"
          :value="documentA"
          :options="cmOptions"
        />
      </div>
      <div
        v-if="documentB !== null"
        v-show="documentB && documentB != '{}'"
        class="col-6"
        @mouseover="enableScrollRightBox"
        @mouseleave="disableScrollRightBox"
      >
        <codemirror
          ref="rightBox"
          :value="documentB"
          :options="cmOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";

import { compare } from "fast-json-patch";
import { parse } from "json-source-map";

export default {
  name: "ConfigurationCompare",
  components: {
    codemirror
  },
  props: {
    currentConfig: {
      type: String,
      required: true
    },
    previousConfig: {
      default () {
        return null;
      },
      type: String,
      required: false
    }
  },
  data () {
    return {
      documentA: null,
      documentB: null,
      leftBoxScrollingActive:false,
      rightBoxScrollingActive:false,
      cmOptions: {
        mode: {
          name: "javascript",
          json: true
        },
        lineNumbers: true
      }
    };
  },
  mounted ()
  {
    this.showDifference();
  },
  methods: {
    enableScrollLeftBox () {
      if (this.leftBoxScrollingActive) {
        return;
      }
      this.leftBoxScrollingActive = true;
      this.$refs.leftBox.codemirror.on('scroll', this.syncScrollLeftBox);
    },
    disableScrollLeftBox () {
      if (!this.leftBoxScrollingActive) {
        return;
      }
      this.leftBoxScrollingActive = false;
      this.$refs.leftBox.codemirror.off('scroll', this.syncScrollLeftBox);
    },
    syncScrollLeftBox ()
    {
        var scrollInfo = this.$refs.leftBox.codemirror.getScrollInfo();
        this.$refs.rightBox.codemirror.scrollTo(scrollInfo.left, scrollInfo.top);
    },
    enableScrollRightBox () {
      if (this.rightBoxScrollingActive) {
        return;
      }
      this.rightBoxScrollingActive = true;
      this.$refs.rightBox.codemirror.on('scroll', this.syncScrollRightBox);
    },
    disableScrollRightBox () {
      if (!this.rightBoxScrollingActive) {
        return;
      }
      this.rightBoxScrollingActive = false;
      this.$refs.rightBox.codemirror.off('scroll', this.syncScrollRightBox);
    },
    syncScrollRightBox ()
    {
        var scrollInfo = this.$refs.rightBox.codemirror.getScrollInfo();
        this.$refs.leftBox.codemirror.scrollTo(scrollInfo.left, scrollInfo.top);
    },
    showDifference () {
      if (this.currentConfig === null && this.previousConfig === null) {
        return;
      }

      this.prepareDocuments();

      if (this.previousConfig !== null) {
        this.$nextTick(() => {
          this.markChanges();
        });
      }
    },
    prepareDocuments () {
      let spaceCount = 4;
      this.documentA = JSON.stringify(
        JSON.parse(this.currentConfig),
        null,
        spaceCount
      );

      if (this.previousConfig !== null) {
        this.documentB = JSON.stringify(
          JSON.parse(this.previousConfig),
          null,
          spaceCount
        );
      }
    },
    //Find an other library for json-source-map or rewrite this logic
    getStartAndEndPosOfDiff (textValue, diff) {
      let result = parse(textValue);

      let pointers = result.pointers;
      let path = diff.path;
      let pointer = pointers[path];

      let start = {
        line: pointer.key ? pointer.key.line : pointer.value.line,
        ch: pointer.key ? pointer.key.column : pointer.value.column
      };
      let end = {
        line: pointer.valueEnd.line,
        ch: pointer.valueEnd.column
      };

      return {
        start: start,
        end: end
      };
    },
    markChanges () {
      let addColor = "#58fa58";
      let removeColor = "#dd4444";
      let replaceColor = "#e5e833";

      var diffs = compare(
        JSON.parse(this.documentA),
        JSON.parse(this.documentB)
      );

      let doc1 = this.$refs.leftBox.codemirror.getDoc();
      let doc2 = this.$refs.rightBox.codemirror.getDoc();

      diffs.forEach(diff => {
        let position1 = null;
        let position2 = null;

        switch (diff.op) {
          case "remove":
            position1 = this.getStartAndEndPosOfDiff(this.documentA, diff);
            doc1.markText(position1.start, position1.end, {
              readOnly: true,
              css: `background-color:${removeColor}`
            });
            break;
          case "add":
            position2 = this.getStartAndEndPosOfDiff(this.documentB, diff);
            doc2.markText(position2.start, position2.end, {
              css: `background-color:${addColor}`
            });
            break;
          case "replace":
            position1 = this.getStartAndEndPosOfDiff(this.documentA, diff);
            position2 = this.getStartAndEndPosOfDiff(this.documentB, diff);

            doc1.markText(position1.start, position1.end, {
              css: `background-color:${replaceColor}`
            });

            doc2.markText(position2.start, position2.end, {
              css: `background-color:${replaceColor}`
            });
            break;

          default:
            break;
        }
      });
    }
  }
};
</script>